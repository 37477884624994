import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-85fbd3b8"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "size-page-header"
};
export default {
  props: {
    useSubHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    useExtraContent: {
      type: Boolean,
      required: false,
      default: true
    },
    header: {
      type: String,
      required: false,
      default: 'Header'
    },
    subheader: {
      type: String,
      required: false,
      default: 'Sub-Header'
    }
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      var _component_el_row = _resolveComponent("el-row");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_row, {
        class: "banner-row std-padding_line-md"
      }, {
        default: _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "header", {}, function () {
            return [_createElementVNode("h1", _hoisted_1, _toDisplayString(__props.header), 1)];
          })];
        }),
        _: 3
      }), __props.useSubHeader ? (_openBlock(), _createBlock(_component_el_row, {
        key: 0,
        class: "banner-row std-padding_line-lg"
      }, {
        default: _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "sub-header", {}, function () {
            return [_createTextVNode(_toDisplayString(__props.subheader), 1)];
          })];
        }),
        _: 3
      })) : _createCommentVNode("", true), __props.useExtraContent ? (_openBlock(), _createBlock(_component_el_row, {
        key: 1,
        class: "banner-row std-padding_line-md banner-btn"
      }, {
        default: _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "default")];
        }),
        _: 3
      })) : _createCommentVNode("", true)], 64);
    };
  }
};